
const Gameboard = ({ targetWord, data }) => {

  function Row({guess, active, targetWord}) {
    console.log("creating row", guess, targetWord)

    let className = "row"
    if (active) {
      className += " active"
    }

    return (
      <div className={className}>
        {[0, 1, 2, 3, 4].map(
          i => <Cell guess={guess} targetWord={targetWord} ndx={i} key={ i }/>
        )
        }
      </div>
    )
  }

  function Cell({guess, targetWord, ndx}) {
    let className = "cell "
    // console.log("creating cell", guess, word, ndx)
    const char = targetWord.charAt(ndx)
    let guessChar = ""

    if (guess != "") {
      guessChar = guess.charAt(ndx)
      if (guessChar == char) {
        className += "correct"
        // TODO handle character reuse
      } else if (targetWord.includes(guessChar)) {
        className += "present"
      } else {
        className += "not-found"
      }
    }

    return <div className={className}>{guessChar}</div>;
  }

  return (
    <>
      <div className="gameBoard">
        <Row guess={data[0]} active={true} targetWord={targetWord} />
        <Row guess={data[1]} active={true} targetWord={targetWord} />
        <Row guess={data[2]} active={true} targetWord={targetWord} />
        <Row guess={data[3]} active={true} targetWord={targetWord} /> 
        <Row guess={data[4]} active={true} targetWord={targetWord} />
        <Row guess={data[5]} active={true} targetWord={targetWord} />
      </div>
    </>
)
}

export default Gameboard