import React from 'react';
import Infocircle from '../icons/Info-circle.svg' 
import Settings from '../icons/Settings.svg' 
import Moon from '../icons/Moon.svg' 
  
const Nav = () => {
  return (
    <>
      <header>
        <div className='menu-left'>
          <img src={Infocircle} alt="Info" />
        </div>
        <div className='title'>
          Wordly
        </div>
        <div className='menu-right'>
          <img src={Settings} alt="Setting" />
          <img src={Moon} alt="Moon" />
        </div>
      </header>
  </>
)
} 
export default Nav
