import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const keyboardLayout = {
  default: [
    "Q W E R T Y U I O P",
    "A S D F G H J K L",
    "{bksp} Z X C V B N M {enter}"
  ]
}

const keyboardDisplay = {
  "{enter}": "Enter",
  "{bksp}": "<=",
}


let s = ""
let results = ""

const Gamekeyboard = ({inWordList, makeGuess, renderCurrent}) => {

  function keyPressed(key) {
    if (key === "{bksp}") {
      s = s.slice(0, -1)
      renderCurrent(s)
    } else if (key === "{enter}") {
      if (!inWordList(s)) {
        // TODO fix me
        alert("`s`not in word list")
      }
      results = makeGuess(s)
      s = ""
    } else if (s.length < 5){
      s += key
      renderCurrent(s)
    }
    console.info("keypress", key, s)
  }

  return (
    <>
      <Keyboard layout={keyboardLayout} display={keyboardDisplay} onKeyPress={keyPressed}></Keyboard>
    </>
)
} 
export default Gamekeyboard