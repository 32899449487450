import './App.css';
import Nav from './components/Nav'
import Gamekeyboard from './components/Keyboard'
import Gameboard from './components/Gameboard'
import Word from './words'
import { useState } from 'react';

function chooseWord() {
  // TODO implement a manually chosen by date word list
  return Word[Math.floor(Math.random() * (Word.length))]
}

function inWordList(w) {
  return Word.indexOf(w) >= 0
}

function App() {
  const [targetWord, useTargetWord] = useState(chooseWord)
  const [data, setData] = useState(["", "", "", "", "", ""])
  const [currentRow, nextRow] = useState(0)


  function makeGuess(s) {
    console.log("making guess", s)
    nextRow(currentRow+1)
  }

  function renderCurrent(s) {
    data[currentRow] = s
    setData([...data])
  }


  return (
    <div className="App">
      <Nav />
      <div className='game'>
        <Gameboard targetWord={targetWord} data={data} />
        <Gamekeyboard inWordList={inWordList} renderCurrent={renderCurrent} makeGuess={makeGuess}/>
      </div>
    </div>
  );
}

export default App;
